define([
  'jquery',
  'underscore',
  'upx.modules/PaymentModule/collections/Provider',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration.js',
], ($, _, UpxCollection, DefaultShopConfigurationModel) => {
  const Collection = UpxCollection.extend({

    offline: true,

    loaded: false,

    TYPE_ALIAS_UPXPAY: 'UpxPay',
    TYPE_ALIAS_GIFTCARD: 'GiftCard',

    /**
         * Load the collection on login
         */
    load(force) {
      const def = $.Deferred();
      const self = this;

      if (!this.loaded || force) {
        $.when(
          this.fetch({
            params: {
              start: 0,
              limit: 0,
              filters: [{
                name: 'published__=',
                val: true,
              }, {
                name: 'deleted__=',
                val: false,
              }],
            },
          }),
        ).then(() => {
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load the PaymentProvider');
        });
      } else {
        def.resolve();
      }

      return def.promise();
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) {
        this.unload();
      }

      $.when(
        this.load(),
      ).then(
        () => {
          def.resolve();
        },
        (response) => {
          def.reject(response);
        },
      );
      return def.promise();
    },

    /**
         * Removes collection from cache on logout
         */
    unload() {
      this.loaded = false;
      this.reset();
    },

    getByTypeAlias(wantedAlias) {
      return this.findWhere({ 'provider_type.alias': wantedAlias });
    },

    getUpxPayProviderId() {
      const model = this.getActiveUpxPayOrPayNlProvider();

      if (model) {
        return model.get('id');
      }

      return null;
    },

    getFilteredCollection(filter) {
      const filtered = this.filter(filter);
      return new Collection(filtered);
    },

    getActiveUpxPayOrPayNlProvider() {
      const configurationProviderId = DefaultShopConfigurationModel.get('provider_id');
      const providersAllowedForQr = ['UpxPay', 'PayNl'];
      const filtered = this.getFilteredCollection(
        (model) => providersAllowedForQr.includes(model.get('provider_type.alias')),
      );

      if (filtered.length === 1) {
        // If there is only one, return it
        return filtered.first();
      }

      if (filtered.length > 1) {
        // if more than 1, get which is_default from the providers with UpxPay/PayNl type alias
        // if the provider_id in the shop configuration is a UpxPay/PayNl and if it is default, it will be returned here
        const filteredDefault = this.getFilteredCollection(
          (model) => model.get('is_default') && providersAllowedForQr.includes(model.get('provider_type.alias')),
        );

        if (filteredDefault.length > 0) {
          // if found a default
          return filteredDefault.first();
        }

        if (configurationProviderId) {
          // If there is no default
          // Try from the shop configuration and get provider_id and check if it is UpxPay/PayNl
          // should always be 1 if it is a UpxPay/PayNl
          const configurationModel = this.get(configurationProviderId);
          const configurationModelTypeAlias = configurationModel.get('provider_type.alias');
          if (configurationModelTypeAlias === 'UpxPay' || configurationModelTypeAlias === 'PayNl') {
            return configurationModel;
          }
        }
      }

      // if no default and the configurationModel is not a UpxPay/PayNl
      // if none is found this will return a falsy value
      return filtered.first();
    },
  });

  return new Collection();
});
