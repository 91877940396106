define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/models/paymentMethodItem',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.common/components/crypto',

  'modules/common/components/appLocalStorage',
  'modules/common/components/locale',
  'modules/common/components/currency',
], (
  $, _, Backbone,
  PaymentMethodItemModel, PaymentMethodsSettingModel,
  OrderItemCollection, Crypto,
  AppLocalStorage, Locale, Currency,
) => Backbone.Collection.extend({

  model: PaymentMethodItemModel,

  PIN_METHOD: PaymentMethodsSettingModel.PIN_METHOD,
  PIN_EXTRA_METHOD: PaymentMethodsSettingModel.PIN_EXTRA_METHOD,
  GIFTCARD_METHOD: PaymentMethodsSettingModel.GIFTCARD_METHOD,
  EXTERNAL_GIFTCARD_METHOD: PaymentMethodsSettingModel.EXTERNAL_GIFTCARD_METHOD,
  PAYLATER_METHOD: PaymentMethodsSettingModel.PAYLATER_METHOD,
  CASH_METHOD: PaymentMethodsSettingModel.CASH_METHOD,
  VVV_METHOD: PaymentMethodsSettingModel.VVV_METHOD,
  INVOICE_METHOD: PaymentMethodsSettingModel.INVOICE_METHOD,
  OTHER_METHOD: PaymentMethodsSettingModel.OTHER_METHOD,
  QR_CODE_METHOD: PaymentMethodsSettingModel.QR_CODE_METHOD,

  LOCKED_METHOD: PaymentMethodsSettingModel.LOCKED_METHOD,

  initialize() {
    this.on('change:ppu_wt', this.recalculateRestValues, this);
    this.on('remove', this.recalculateRestValues, this);
    this.totalPriceWt = null;
  },

  setTotalPriceWt(totalPriceWt = null) {
    if (totalPriceWt !== null) {
      this.totalPriceWt = Currency.toCurrency(totalPriceWt);
    } else {
      this.totalPriceWt = null;
    }
  },

  getTotalPriceWt() {
    if (this.totalPriceWt !== null) {
      return this.totalPriceWt;
    }
    return OrderItemCollection.getTotalPriceWt();
  },

  addMethodForPrinting(methodId, data = {}) {
    return this.add({
      id: `${methodId}::${this.length}`,
      title: PaymentMethodsSettingModel.getNameByMethod(methodId),
      rest_value: PaymentMethodsSettingModel.allowsRestPaidByMethod(methodId),
      setManually: true,
      ...data,
    });
  },

  addMethodById(id, data = {}) {
    if (!PaymentMethodsSettingModel.methodExists(id)) {
      console.error(`Method ${id} does not exists`);
      return false;
    }

    if (data.max_amount) {
      data.max_amount = Currency.toCurrency(data.max_amount);
    } else {
      data.max_amount = null;
    }
    const model = this.add({
      id,
      title: PaymentMethodsSettingModel.getNameByMethod(id),
      rest_value: PaymentMethodsSettingModel.allowsRestPaidByMethod(id),
      convert_to_change: PaymentMethodsSettingModel.convertToChangeByMethod(id),
      lockedAlreadyPaid: false,
      ...data,
    });

    this.recalculateRestValues();

    const editPrice = [this.PIN_EXTRA_METHOD, this.CASH_METHOD].indexOf(id) !== -1;
    if (editPrice) {
      this.modelEditPrice(model);
    }

    return model;
  },

  lockMethod(id) {
    const oldModel = this.get(id);
    const modelData = $.extend(true, {}, oldModel.toJSON());
    modelData.id = `${this.LOCKED_METHOD}::${Crypto.uuid()}`;
    modelData.lockedAlreadyPaid = true;

    const model = this.add(modelData);
    this.remove(oldModel);
    return model;
  },

  addGiftCardMethod(code, balance, gift_card_id) {
    const id = `${this.GIFTCARD_METHOD}::${code}`;
    return this.addMethodById(id, {
      code,
      balance: Currency.toCurrency(balance),
      gift_card_id,
    });
  },

  addExternalGiftCardMethod(
    code,
    balance,
    title = PaymentMethodsSettingModel.getNameByMethod(this.GIFTCARD_METHOD),
    icon,
    provider_method_id,
    provider_id,
    pin = null,
    max_amount = null,
  ) {
    const id = `${this.EXTERNAL_GIFTCARD_METHOD}::${code}`;
    return this.addMethodById(id, {
      code,
      pin,
      title,
      requiresPin: true,
      icon,
      balance: Currency.toCurrency(balance),
      provider_method_id,
      provider_id,
      max_amount,
    });
  },

  modelEditPrice(model) {
    setTimeout(() => {
      const $el = $(`[data-payment-method="${model.get('id')}"]`);
      $el.click();
    }, 10);
  },

  recalculateRestValues() {
    // getting all model which should be recalculated
    const restModels = new Backbone.Collection(this.where({
      rest_value: true,
      setManually: false,
      convert_to_change: false,
    }));

    if (restModels.length > 0) {
      // get total for all the other models
      const totalPayments = this.getTotalPaymentAgainstOrderWt(
        (model) => !restModels.get(model.get('id')),
      );
      const orderPrice = this.getTotalPriceWt();
      let restValue = Currency.Math.subtract(
        orderPrice,
        totalPayments,
      );
      restModels.each((restModel) => {
        let newPpuWt = restValue; // we try to assign everything
        const max_amount = restModel.get('max_amount');
        if (max_amount !== null && parseFloat(max_amount) < parseFloat(newPpuWt)) {
          newPpuWt = max_amount;
        }
        if (restValue < 0) {
          newPpuWt = restValue = '0.00';
        } else if (restModel.has('balance')) {
          // check if exceed the value
          const balance = restModel.get('balance');
          const balanceDiff = Currency.Math.subtract(newPpuWt, balance);
          if (parseFloat(balanceDiff) > 0) {
            // not enough on balance, assing what we can
            newPpuWt = balance;
            restValue = balanceDiff;
          } else {
            restValue = '0.00';
          }
        } else {
          restValue = '0.00';
        }

        if (newPpuWt !== restModel.get('ppu_wt')) {
          restModel.setPpuWt(newPpuWt);
        }
      });
    }
  },

  getTotalPaymentAgainstOrderWt(filter = (() => true)) {
    let totalPayments = '0.00';
    this.each((model) => {
      if (filter(model) && !model.get('convert_to_change')) {
        totalPayments = Currency.Math.add(
          totalPayments,
          model.get('ppu_wt'),
        );
      }
    });
    return totalPayments;
  },

  getSpareChangeWt(orderValueWt) {
    let amount = Currency.Math.subtract(
      orderValueWt || this.getTotalPriceWt(),
      this.getTotalPaymentAgainstOrderWt(),
    );
    if (parseFloat(amount) < 0) {
      amount = amount.slice(1);
    } else {
      amount = '0.00';
    }
    // add the converted after
    this.each((model) => {
      if (model.get('convert_to_change')) {
        amount = Currency.Math.add(
          amount,
          model.get('ppu_wt'),
        );
      }
    });
    return amount;
  },

  getLeftToPayWt() {
    let amount = Currency.Math.subtract(
      this.getTotalPriceWt(),
      this.getTotalPaymentAgainstOrderWt(),
    );

    if (parseFloat(amount) < 0) {
      amount = '0.00';
    }

    return amount;
  },

  existsById(id) {
    return this.indexOf(id) !== -1;
  },

  getQrMethodName() {
    return PaymentMethodsSettingModel.getNameByMethod(this.QR_CODE_METHOD);
  },

  filterByNonemptyMethod(methodAlias) {
    return this.filter((model) => model.get('id').startsWith(methodAlias) && model.get('ppu_wt') !== '0.00');
  },

}));
