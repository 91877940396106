define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ProductsModule/collections/TranslatedCategory',

  'modules/common/components/moment',
  'modules/common/components/locale',
], (
  $, _, Backbone, TranslatedCategoryCollection,
  Moment, Locale,
) => {
  const Collection = TranslatedCategoryCollection.extend({

    dateLastFetch: null,

    load() {
      const def = new $.Deferred();

      if (this.allowNextFetch()) {
        this.fetchAll(this._getParameters())
          .then(() => {
            this.dateLastFetch = new Moment();
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    allowNextFetch() {
      // Check if there was ever a fetch
      if (!this.dateLastFetch) return true;

      // Check if the last fetch was more then an 30 minutes
      const currentDate = new Moment();
      return currentDate.diff(this.dateLastFetch, 'minutes') > 30;
    },

    _getParameters() {
      const parameters = {
        lang: Locale.getLocale(),
        start: 0,
        limit: 250,
        filters: [{
          name: 'published__=',
          val: true,
        }],
      };

      // Add last fetch parameter
      if (this.dateLastFetch) {
        parameters.filters.push({
          name: 'date_created__>', // No date_updated parameter
          val: this.dateLastFetch.format(),
        });
      }

      return {
        params: parameters,
        add: true,
        merge: true,
        remove: false,
      };
    },

    unload() {
      this.reset();
      this.dateLastFetch = null;
    },

  });

  return new Collection();
});
