define([
  'jquery',
  'underscore',
  'upx.modules/PaymentModule/collections/ProviderMethod',
], ($, _, UpxCollection) => {
  const Collection = UpxCollection.extend({

    CASH_ALIAS: 'Cash',
    TERMINALDEVICE_ALIAS: 'TerminalDevice',
    WEB_ALIAS: 'Web',
    VVVBON_ALIAS: 'VvvBon',
    GIFTCARD_ALIAS: 'GiftCard',
    EXTERNAL_GIFTCARD_ALIAS: 'ExternalGiftCard',

    ONINVOICE_ALIAS: 'OnInvoice',
    ONORDER_ALIAS: 'OnOrder',
    OTHER_ALIAS: 'Other',

    offline: true,

    loaded: false,

    /**
         * Load the collection on login
         */
    load(force) {
      const def = $.Deferred();
      const self = this;

      if (!this.loaded || force) {
        $.when(
          this.fetch({
            params: {
              start: 0,
              limit: 0,
              filters: [{
                name: 'active__=',
                val: true,
              }],
            },
          }),
        ).then(() => {
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load the PaymentProviderMethod');
        });
      } else {
        def.resolve();
      }

      return def.promise();
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) {
        this.unload();
      }

      $.when(
        this.load(),
      ).then(
        () => {
          def.resolve();
        },
        (response) => {
          def.reject(response);
        },
      );
      return def.promise();
    },

    /**
         * Removes collection from cache on logout
         */
    unload() {
      this.loaded = false;
      this.reset();
    },

    getProviderMethodIdByAlias(alias) {
      const model = this.getModelByAlias(alias);
      return model ? model.get('id') : null;
    },

    getAllProviderMethodIdsByMethodTypeAlias(alias) {
      alias = alias.toLowerCase();
      const filteredCollection = this.filter((model) => {
        let value = model.get('provider_method_type.alias');
        if (value) {
          value = value.toLowerCase();
          return value === alias;
        }
        return false;
      });
      return _.pluck(filteredCollection, 'id');
    },

    /**
         *
         * @param alias
         * @return {*}
         */
    getModelByAlias(alias) {
      alias = alias.toLowerCase();
      const filtered = this.filter((model) => {
        let value = model.get('provider_method_type.alias');
        if (value) {
          value = value.toLowerCase();
          return value === alias;
        }
        return false;
      });
      return _.first(filtered);
    },

    getFilteredCollection(filter) {
      const filtered = this.filter(filter);
      return new Collection(filtered);
    },
  });

  return new Collection();
});
