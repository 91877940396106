define([
  'jquery',
  'module',
  'upx.modules/ShippingModule/collections/ShippingType',
],
(
  $, Module, UpxCollection,
) => {
  const model = UpxCollection.extend({

    offline: false,
    def: $.Deferred(),

    load() {
      $.when(
        this.fetch(this._getParams()),
      ).then(
        () => {
          this.loaded = true;
          this.def.resolve();
        },
        this.def.reject,
      );

      return this.def.promise();
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) {
        this.unload();
      }

      $.when(
        this.load(),
      ).then(
        () => {
          def.resolve();
        },
        (response) => {
          def.reject(response);
        },
      );

      return def.promise();
    },

    _getParams() {
      return {
        params: {
          start: 0,
          limit: 0,
          filters: [
            {
              name: 'alias__!=',
              val: 'FlatRate',
            },
          ],
        },
        add: true,
        remove: false,
        merge: true,
      };
    },

    /**
       * Unload the collection
       */
    unload() {
      this.loaded = false;
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup(); // clean the cache to not load old profile
      }
    },

    /**
       * @description Checks if the attribute exists with a similar name, returns false when not loaded.
       * @return {boolean}
       */
    existsByName(name) {
      if (!this.loaded) {
        return false;
      }

      return !!this.findWhere({
        name,
      });
    },

  });

  return new model();
});
