define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/CalendarAppointment',
  'modules/shop.cash-register-retail/models/employeeCalendarItems',

  'modules/shop.cash-register-retail/collections/employeeCalendar',

  'modules/common/components/moment',
  'modules/common/components/locale',
  'modules/common/components/string',
], (
  $, _, Backbone, ItemCollection, EmployeeCalendarItems,
  EmployeeCalendarCollection,
  Moment,
) => {
  const Collection = ItemCollection.extend({

    model: EmployeeCalendarItems,

    dateLastFetch: null,

    load(force) {
      const def = new $.Deferred();

      if (force || this.allowNextFetch()) {
        EmployeeCalendarCollection.load()
          .then(() => {
            this.fetchAll(this._getParameters())
              .then(() => {
                this.removeOldItems();
                this.dateLastFetch = new Moment();
                def.resolve();
              }, def.reject);
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    removeOldItems() {
      const modelToRemove = [];

      this.each((model) => {
        const lastWeekDate = new Moment().startOf('week').subtract('weeks', 1);
        const modelDate = new Moment(model.get('item.date_start'));

        if (modelDate.diff(lastWeekDate, 'minutes') < 0) {
          modelToRemove.push(model);
        }
      });

      this.remove(modelToRemove);
    },

    allowNextFetch() {
      // Check if there was ever a fetch
      if (!this.dateLastFetch) return true;

      // Check if the last fetch was more then an 30 minutes
      const currentDate = new Moment();
      return currentDate.diff(this.dateLastFetch, 'minutes') > 30;
    },

    _getParameters() {
      const parameters = {
        start: 0,
        limit: 250,
        filters: [
          {
            name: 'item/calendar_id__in_list',
            multi_val: EmployeeCalendarCollection.pluck('id'),
          }, {
            name: 'item/date_start__>',
            val: new Moment().startOf('week').subtract('weeks', 1).format(),
          },
        ],
      };

      // Add last fetch parameter
      if (this.dateLastFetch) {
        parameters.filters.push({
          name: 'item/date_updated__>',
          val: this.dateLastFetch.format(),
        });
      }

      return {
        params: parameters,
        add: true,
        merge: true,
        remove: false,
      };
    },

    unload() {
      this.reset();
      this.dateLastFetch = null;
    },

  });

  return new Collection();
});
