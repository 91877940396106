define([
  'jquery',
  'underscore',
  'upx.modules/ProductsModule/collections/ProductPriceType',
], ($, _, UpxCollection) => {
  const collection = UpxCollection.extend({
    offline: true,
    cache: true,
    cacheOptions: {
      maxAge: 10 * 60 * 1000, // 10 mins
    },
  });

  return collection;
});
