define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/models/tab',

  'modules/common/components/appLocalStorage',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/upx/components/upx',
  'modules/shop.common/components/deviceConfig',
], (
  $, _, Backbone,
  TabModel,
  AppLocalStorage, CashRegisterApi, Upx, DeviceConfig,
) => {
  const Collection = Backbone.Collection.extend({

    // Backwards compatibility
    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/collections/table'),

    model: TabModel,

    initialize() {
      this.load()
        .then(() => {
          this.startListeners();
        });
    },

    load() {
      const def = $.Deferred();
      this.fetch()
        .done(() => {
          this.removeAddModel();
          def.resolve();
        }, def.reject);
      return def;
    },

    /**
		 * For old collections
		 */
    removeAddModel() {
      const addModel = this.findWhere({ type: 'add' });
      this.remove(addModel);
    },

    startListeners() {
      this.on('add', this.logAdd, this);
      this.on('remove', this.logRemove, this);
      this.on('change', this.logChange, this);
    },

    logAdd(model) {
      CashRegisterApi.logAction('TABLE_ADD', model.toJSON());
      this.updateTabCache(model);
    },

    logChange(model) {
      CashRegisterApi.logAction('TABLE_CHANGE', model.toJSON());
      this.updateTabCache(model);
    },

    logRemove(model) {
      CashRegisterApi.logAction('TABLE_REMOVE', model.toJSON());
      this.removeTabCache(model);
    },

    removeTabCache: _.debounce((model) => {
      if (model.has('id')) {
        Upx.call('CommerceModule', 'deleteTableCache',
          {
            uuid: model.get('id'),
          });
      }
    }, 1000),

    updateTabCache: _.debounce((model) => {
      if (model.has('id')) {
        Upx.call('CommerceModule', 'setTableCache',
          {
            fields: {
              uuid: model.get('id'),
              device_uuid: DeviceConfig.getDeviceUUID(),
              cache: model.toJSON(),
            },
          });
      }
    }, 1000),

    addNew(name) {
      const model = this.add({
        name,
      });
      model.save(); // Save the collection to local storage.
      return model;
    },

    clearTab(id) {
      const model = this.get(id);
      if (model) {
        model.clearTab();
      }
    },

  });

  return new Collection();
});
