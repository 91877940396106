define([
  'upx.modules/ShippingModule/collections/ShipmentStatusType',
  'modules/common/components/locale',
],
(
  UpxCollection, Locale,
) => {
  const Collection = UpxCollection.extend({

    STATUS_NEW: 'New',
    STATUS_IN_PREPARATION: 'InPreparation',
    STATUS_WAITING_FOR_PICKUP: 'WaitingForPickup',
    STATUS_WAITING_FOR_CUSTOMER_PICKUP: 'WaitingForCustomerPickup',
    STATUS_IN_TRANSIT: 'InTransit',
    STATUS_DELIVERED: 'Delivered',

    offline: false,

    getIdByAlias(module_name, alias) {
      if (!this.isLoaded()) {
        throw new Error('ShipmentStatusType collection is not loaded');
      }
      const model = this.findWhere({ module_name, alias });
      if (!model) {
        throw new Error(`ShipmentStatusType(${module_name}::${alias}) not found`);
      }
      return model.get('id');
    },

    getDeliveredId() {
      return this.getIdByAlias('ShippingModule', this.STATUS_DELIVERED);
    },
    getWaitingForCustomerPickupId() {
      return this.getIdByAlias('ShippingModule', this.STATUS_WAITING_FOR_CUSTOMER_PICKUP);
    },

    getStatusName(status) {
      if (status === this.STATUS_IN_PREPARATION) {
        return Locale.translate('in_preparation');
      }
      if (status === this.STATUS_NEW) {
        return Locale.translate('new');
      }
      if (status === this.STATUS_IN_TRANSIT) {
        return Locale.translate('in_transit');
      }
      if (status === this.STATUS_WAITING_FOR_CUSTOMER_PICKUP) {
        return Locale.translate('waiting_for_customer_pickup');
      }
      if (status === this.STATUS_WAITING_FOR_PICKUP) {
        return Locale.translate('waiting_for_pickup');
      }
      if (status === this.STATUS_DELIVERED) {
        return Locale.translate('delivered');
      }
      console.error('Unknown ShipmentStatusType status', status);
      return '';
    },

    getStatusLabelClass(status) {
      if (status === this.STATUS_NEW
          || status === this.STATUS_IN_PREPARATION
          || status === this.STATUS_IN_TRANSIT
      ) {
        return 'label-default';
      }
      if (status === this.STATUS_WAITING_FOR_CUSTOMER_PICKUP
          || status === this.STATUS_WAITING_FOR_PICKUP
      ) {
        return 'label-info';
      }
      if (status === this.STATUS_DELIVERED) {
        return 'label-success';
      }
      console.error('Unknown ShipmentStatusType status', status);
      return '';
    },
  });

  return new Collection();
});
