define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/orderItem',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
  'modules/common/components/uuid',

  'modules/common/components/locale',
  'modules/common/components/currency',
  'modules/shop.common/components/crypto',

  'modules/shop.cash-register-retail/components/configurableSelection',
  'modules/shop.cash-register-retail/components/addonItem',
  'modules/shop.cash-register-retail/components/productCache',
  'modules/shop.cash-register-retail/components/productStock',

  'modules/shop.cash-register-retail/views/popups/confirmPopup',
], (
  $, _, Backbone, OrderItemModel,
  DefaultShopConfigurationModel, FeaturedAttributeCollection, Uuid,
  Locale, Currency, Crypto,
  ConfigurableSelection, AddonItem, ProductCache, ProductStock,
  ConfirmPopupView,
) => {
  const OrderItemCollection = Backbone.Collection.extend({

    TYPE_PRODUCT: 'type-product',
    TYPE_LOADER: 'type-loader',
    TYPE_MEMBERCARD: 'type-membercard',
    TYPE_MEMBERCARD_GIFTCARD: 'type-membercard-giftcard',
    TYPE_GIFTCARD: 'type-giftcard',
    TYPE_EMBALLAGE: 'type-emballage',
    TYPE_TOP_UP: 'type-top-up',
    TYPE_NEGATIVE_PRODUCT: 'type-negative-product',
    TYPE_USED_GOODS: 'type-used-goods',

    model: OrderItemModel,

    initialize(items) {
      if (_.isArray(items)) {
        items.forEach((item) => {
          if ('subitems' in item) {
            item.sub_items = item.subitems;
            delete item.subitems;
          }
        });
      }
    },

    addLoaderByDef(def) {
      const orderItemData = {
        before_discount_ppu: '0.00',
        before_discount_ppu_wt: '0.00',
        ppu: '0.00',
        ppu_wt: '0.00',
        currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(), // Tax is not a field needed to be saved;
        tax: '0.00', // Tax is not a field needed to be saved;
        quantity: '0',
        discount_percentage: '0.00',
        discount_ppu_wt: '0.00',
        length: '0.00',
        length_ppu_wt: '0.00',
        shop_product_id: Crypto.uuid(),
        type: this.TYPE_LOADER,
        time: new Date().getTime(),

        // Stock
        stock_quantity: 0,
        stock_unlimited: false,
        stock_backorder: false,
      };
      const model = this.add(orderItemData);

      def.always(() => {
        model.destroy();
      });

      return model;
    },

    getOrderItemModelByShopProductId(id) {
      if (Crypto.isUuid(id)) {
        // left the console error for later, to make sure no legacy code generates uuid there
        console.error(`shopProductId is uuid ${id}`);
      } else {
        id = parseInt(id);
      }
      return this.findWhere({ id });
    },

    isCardType(type) {
      switch (type) {
        case this.TYPE_MEMBERCARD_GIFTCARD:
        case this.TYPE_GIFTCARD:
        case this.TYPE_TOP_UP:
        case this.TYPE_MEMBERCARD:
          return true;
      }
      return false;
    },

    setDiscountOnProduct(orderItemData, salesPpuWt) {
      if (!!orderItemData.ppu_wt && !!salesPpuWt
          && parseFloat(orderItemData.ppu_wt) > parseFloat(salesPpuWt)) {
        salesPpuWt = Currency.toCurrency(salesPpuWt);

        // there is some discount
        orderItemData.discount_ppu_wt = Currency.Math.subtract(
          orderItemData.ppu_wt,
          salesPpuWt,
        );
        orderItemData.discount_percentage = Currency.Math.mul('100.00',
          Currency.Math.div(
            orderItemData.discount_ppu_wt,
            orderItemData.ppu_wt,
          ));
      }
    },

    newOrderItemModel(orderItemData) {
      orderItemData.time = new Date().getTime();
      orderItemData.type = orderItemData.type || this.TYPE_PRODUCT;

      const ensureItemData = (key, fallback) => {
        if (!(key in orderItemData)) {
          orderItemData[key] = fallback;
        }
      };

      ensureItemData('length', '0.00');
      ensureItemData('length_ppu_wt', '0.00');
      ensureItemData('description', '');
      ensureItemData('order_description', '');
      ensureItemData('has_serial_numbers', false);
      ensureItemData('serial_nos', []);
      ensureItemData('discount_percentage', '0.00');
      ensureItemData('discount_ppu_wt', '0.00');
      ensureItemData('quantity', '1');
      ensureItemData('has_addons', false);
      ensureItemData('needs_weight', false);
      ensureItemData('needs_description', false);
      ensureItemData('product_type', 'simple');
      ensureItemData('stock_quantity', 0);
      ensureItemData('stock_unlimited', false);
      ensureItemData('stock_backorder', false);
      ensureItemData('percentage', false);
      ensureItemData('unfulfilled_quantity', parseInt(orderItemData.quantity, 10));
      ensureItemData('to_be_shipped_quantity', parseInt(orderItemData.quantity, 10));
      ensureItemData('order_item_id', 0);

      if (!('before_discount_ppu' in orderItemData)) {
        orderItemData.before_discount_ppu = orderItemData.ppu;
      }
      if (!('before_discount_ppu_wt' in orderItemData)) {
        orderItemData.before_discount_ppu_wt = orderItemData.ppu_wt;
      }

      if (Crypto.isUuid(orderItemData.shop_product_id)) {
        // left the console error for later, to make sure no legacy code generates uuid there
        console.error(`shopProductId is uuid ${orderItemData.shop_product_id}`);
      } else {
        orderItemData.shop_product_id = parseInt(orderItemData.shop_product_id);
      }
      if (!('id' in orderItemData)) {
        orderItemData.id = orderItemData.shop_product_id;
      }
      const ModelCls = this.model;
      return new ModelCls(orderItemData);
    },

    addOrderItemData(orderItemData) {
      const model = this.newOrderItemModel(orderItemData);
      return this.add(model);
    },

    applyPercentageDiscountToAllItemsWithConfirmation(percentage) {
      const def = new $.Deferred();
      let hasProductOnDiscount = false;
      this.each((orderItemModel) => {
        if (
          orderItemModel.get('quantity') > 0
            && parseFloat(orderItemModel.get('discount_percentage')) !== 0
        ) {
          hasProductOnDiscount = true;
        }
      });
      if (hasProductOnDiscount) {
        // timeout is needed to let other popups close,
        // this sometimes conflicts and closes the following popup too
        setTimeout(() => {
          const view = new ConfirmPopupView();
          view.open(
            Locale.translate('are_you_sure_question'),
            Locale.translate('there_are_currently_one_or_more_discounted_products_in_the_checkout_are_you_sure_you_want_to_apply_a_discount_over_all_products_this_will_overwrite_the_existing_discount_dot'),
          ).then(() => {
            this.applyPercentageDiscountToAllItems(percentage);
            def.resolve();
          }, def.reject);
        }, 100);
      } else {
        this.applyPercentageDiscountToAllItems(percentage);
        def.resolve();
      }
      return def.promise();
    },

    applyPercentageDiscountToAllItems(percentage) {
      this.each((orderItemModel) => {
        if (orderItemModel.get('quantity') > 0) {
          orderItemModel.setDiscountPercentage(percentage);
          orderItemModel.calculateTotalPrices();
          orderItemModel.save();
          orderItemModel.trigger('discount:updated'); // Trigger the updating of the discount values in the discount cell.
        }
      });
    },

    addProductByShopFlatProductModel(shopFlatProductModel, quantity, forceNew, type) {
      quantity = quantity || 1;

      const def = new $.Deferred();
      this.addLoaderByDef(def);
      if (shopFlatProductModel.get('flat_product.product.type') === 'configurable') {
        ConfigurableSelection.showConfigurablePopup(shopFlatProductModel)
          .then(
            (model) => def.resolve(model, shopFlatProductModel),
            def.reject,
          );
      } else if (shopFlatProductModel.get('flat_product.product.type') === 'configurable_assign') {
        ConfigurableSelection.getConfigurableProductForAssociatedId(
          shopFlatProductModel.get('product_id'),
        ).then(
          (configurableModel) => def.resolve(shopFlatProductModel, configurableModel),
          def.reject,
        );
      } else {
        def.resolve(shopFlatProductModel);
      }

      const itemDef = new $.Deferred();
      def.then(
        (model, configurableModel) => {
          const item = this.addSimpleProductByShopFlatProductModel(
            model, quantity, forceNew, type,
          );
          const hasAddons = item.get('has_addons');
          const configurableHasAddons = !!configurableModel && configurableModel.get('flat_product.product.has_addons');
          if (hasAddons || configurableHasAddons) {
            AddonItem.showAddonPopup(item, configurableModel).then(
              () => itemDef.resolve(item),
              itemDef.reject,
            );
          } else {
            itemDef.resolve(item);
          }
        },
        itemDef.reject,
      );
      itemDef.fail((error) => {
        console.error('Failed to add product to order item', error);
      });
      return itemDef.promise();
    },

    async refreshProductStockIfProductStockIsUsed(orderItemModel) {
      if (ProductStock.useStockValues()) {
        return this.refreshProductStock(orderItemModel);
      }
      return false;
    },
    async refreshProductStock(orderItemModel) {
      orderItemModel.set('stock-check', true);
      try {
        const newCache = await ProductCache.refreshProductStockAndPrice(orderItemModel.get('shop_product_id'));
        if (newCache) {
          const {
            has_serial_numbers,
            stock_quantity,
            stock_unlimited,
            stock_backorder,
          } = this.getOrderItemDataFromFlatProduct(
            new Backbone.DeepModel(newCache.get('shop_flat_product')),
          );
          orderItemModel.set({
            has_serial_numbers,
            stock_quantity,
            stock_unlimited,
            stock_backorder,
          });
          return true;
        }
      } catch (e) {
        console.error('Failed to refresh stock', e);
      } finally {
        orderItemModel.set('stock-check', false);
      }
      return false;
    },

    getOrderItemDataFromFlatProduct(shopFlatProductModel, quantity = 1, type = null) {
      const shopProductId = shopFlatProductModel.get('id');
      const featured_attributes = FeaturedAttributeCollection.getFeatureAttributesFromShopFlatProduct(shopFlatProductModel);
      const featured_attributes_model = new Backbone.DeepModel(featured_attributes);
      let stockQuantity = shopFlatProductModel.get('flat_product.product.product_stock.value') || 0;
      if (shopFlatProductModel.has('orderable_stock_value')) {
        stockQuantity = shopFlatProductModel.get('orderable_stock_value');
      }
      const orderItemData = {
        ppu: Currency.toCurrency(shopFlatProductModel.get('product_default_price.ppu')),
        ppu_wt: Currency.toCurrency(shopFlatProductModel.get('product_default_price.ppu_wt')),
        currency_iso3: shopFlatProductModel.get('product_price.currency_iso3'),
        tax: shopFlatProductModel.get('product_price.tax'),
        tax_rate_id: shopFlatProductModel.get('product_price.tax_rate_id'),
        quantity: quantity.toString(),
        sku: shopFlatProductModel.get('flat_product.product.sku'),
        has_serial_numbers: !!shopFlatProductModel.get('flat_product.product.has_serial_numbers'),
        name: shopFlatProductModel.get('flat_product.title'),
        summary: shopFlatProductModel.get('flat_product.summary'),
        has_addons: shopFlatProductModel.get('flat_product.product.has_addons'),
        needs_weight: featured_attributes_model.get(`${FeaturedAttributeCollection.ALIAS_NEEDS_WEIGHT_ON_KASSA}.value`) === '1',
        needs_description: featured_attributes_model.get(`${FeaturedAttributeCollection.ALIAS_NEEDS_DESCRIPTION_ON_KASSA}.value`) === '1',
        shop_product_id: shopProductId,
        product_id: shopFlatProductModel.get('product_id'),
        featured_attributes,
        type: type || this.TYPE_PRODUCT,
        product_type: shopFlatProductModel.get('flat_product.product.type'),

        // Stock
        stock_quantity: stockQuantity,
        stock_unlimited: !!shopFlatProductModel.get('flat_product.product.product_stock.unlimited'),
        stock_backorder: !!shopFlatProductModel.get('backorder_enabled'),
      };
      return orderItemData;
    },

    addSimpleProductByShopFlatProductModel(shopFlatProductModel, quantity, forceNew, type) {
      const orderItemData = this.getOrderItemDataFromFlatProduct(
        shopFlatProductModel, quantity, type,
      );
      const shopProductId = shopFlatProductModel.get('id');
      if (!forceNew) {
        forceNew = this.requiresNewOrderItem(orderItemData);
      }

      let model = null;
      if (forceNew) {
        orderItemData.id = Uuid.genRandom();
      } else {
        model = this.getOrderItemModelByShopProductId(shopProductId);
        // Check if the model already exists > add quantity
        if (model) {
          model.addQuantity(quantity);
        }
      }

      // add new the product (forceNew or does not exist)
      if (!model) {
        this.setDiscountOnProduct(
          orderItemData,
          shopFlatProductModel.get('product_price.ppu_wt'),
        );
        model = this.addOrderItemData(orderItemData);

        if (model.get('needs_weight')) {
          model.trigger('product:needs-weight');
        } else if (model.get('needs_description')) {
          model.trigger('product:needs-description');
        }
      }
      model.save();
      return model;
    },

    cloneCollection() {
      return new OrderItemCollection(
        JSON.parse(JSON.stringify(this.toJSON())),
      );
    },
    /**
     * This function check if it is required to add a order item.
     * For example, if a product has a description. it needs to add a new order item
     * @param order_item_data
     * @return {boolean}
     */
    requiresNewOrderItem(order_item_data) {
      if (!('shop_product_id' in order_item_data)) {
        return false;
      }

      if (
        order_item_data.has_addons
          || order_item_data.needs_weight
          || order_item_data.needs_description
      ) {
        return true;
      }

      const current_order_model = this.getOrderItemModelByShopProductId(order_item_data.shop_product_id);

      if (!current_order_model) {
        return false;
      }

      const newDescription = order_item_data.description;
      const currentDescription = current_order_model.get('description');
      if (newDescription && newDescription.trim() !== currentDescription.trim()) {
        return true;
      }
      if (current_order_model.get('description').trim() !== '') {
        return true;
      }

      if (parseInt(current_order_model.get('quantity')) < 0) {
        return true;
      }

      return false;
    },
    addProductByOrderItem({
      orderItem, quantity, forceNew, saveModel, useFlatProductName,
    }) {
      return this.addProductByOrderItemModel({
        orderItemModel: new Backbone.DeepModel(orderItem),
        quantity,
        forceNew,
        saveModel,
        useFlatProductName,
      });
    },
    getOrderItemDataFromModel({
      orderItemModel, quantity = null, useFlatProductName = true,
    }) {
      if (quantity === null) {
        quantity = orderItemModel.get('quantity') || 0;
      }

      let name = orderItemModel.get('name');
      if (useFlatProductName && orderItemModel.has('flat_product.title')) {
        name = orderItemModel.get('flat_product.title');
      }
      const sku = orderItemModel.has('flat_product.product.sku') ? orderItemModel.get('flat_product.product.sku') : orderItemModel.get('sku');
      const unfulfilled_quantity = orderItemModel.get('unfulfilled_quantity') || false;
      const has_serial_numbers = orderItemModel.get('flat_product.product.has_serial_numbers') || false;
      const serial_nos = orderItemModel.get('serial_nos') || [];

      let tax;
      if (orderItemModel.has('tax')) {
        tax = orderItemModel.get('tax');
      } else {
        tax = orderItemModel.get('tax_rate.value');
      }

      const orderItemData = {
        ppu: Currency.toCurrency(orderItemModel.get('ppu')),
        ppu_wt: Currency.toCurrency(orderItemModel.get('ppu_wt')),
        currency_iso3: orderItemModel.get('currency_iso3'),
        tax,
        tax_rate_id: orderItemModel.get('tax_rate_id'),
        quantity: quantity.toString(),
        sku,
        name,
        has_serial_numbers,
        serial_nos,
        shop_product_id: orderItemModel.get('shop_product_id'),
        product_id: orderItemModel.get('product_id'),
        is_product: orderItemModel.get('is_product'),
        is_payment: orderItemModel.get('is_payment'),
        is_shipping: orderItemModel.get('is_shipping'),
        has_addons: orderItemModel.get('has_addons'),
        sub_items: orderItemModel.get('sub_items'),
        description: (orderItemModel.get('description') || '').trim(),
        // Stock
        stock_quantity: orderItemModel.get('stock_quantity') || 0,
        stock_unlimited: !!orderItemModel.get('stock_unlimited'),
        stock_backorder: !!orderItemModel.get('stock_backorder'),
        // Shipping data
        to_be_shipped_quantity: orderItemModel.get('to_be_shipped_quantity') || 0,
        unfulfilled_quantity: _.isNumber(unfulfilled_quantity) ? unfulfilled_quantity : quantity,
        // Meta data
        order_item_id: orderItemModel.get('id'),
      };
      return orderItemData;
    },
    addProductByOrderItemModel({
      orderItemModel, quantity = null, forceNew = false,
      saveModel = true, useFlatProductName = true,
    }) {
      const orderItemData = this.getOrderItemDataFromModel(
        { quantity, orderItemModel, useFlatProductName },
      );
      if (!forceNew) {
        forceNew = this.requiresNewOrderItem(orderItemData);
      }

      if (forceNew) {
        orderItemData.id = Uuid.genRandom();
      }

      const model = this.addOrderItemData(orderItemData);
      if (saveModel) model.save();
      return model;
    },

    getTotalItems() {
      let totalItems = 0;

      this.each((model) => {
        totalItems += parseInt(model.get('quantity'));
      });

      return totalItems;
    },

    getTotalPrice() {
      let totalPrice = '0.00';

      this.each((model) => {
        totalPrice = Currency.Math.add(
          totalPrice,
          model.get('price') || '0.00',
        );
        if (model.has('sub_items')) {
          const subItems = model.getCalculatedSubItems();
          subItems.forEach((item) => {
            totalPrice = Currency.Math.add(
              totalPrice,
              item.price || '0.00',
            );
          });
        }
      });

      return totalPrice;
    },

    getTotalPriceWt() {
      let totalPrice = '0.00';

      this.each((model) => {
        let price = model.get('price_wt');
        if (typeof price === 'number') {
          price = Currency.toCurrency(price);
        }
        totalPrice = Currency.Math.add(
          totalPrice,
          price || '0.00',
        );
        if (model.has('sub_items')) {
          const subItems = model.getCalculatedSubItems();
          subItems.forEach((item) => {
            let priceSubitem = item.price_wt;
            if (typeof priceSubitem === 'number') {
              priceSubitem = Currency.toCurrency(priceSubitem);
            }
            totalPrice = Currency.Math.add(
              totalPrice,
              priceSubitem || '0.00',
            );
          });
        }
      });

      return totalPrice;
    },

    getTotalPriceWtWithoutDiscount() {
      const self = this;
      let totalPrice = '0.00';

      this.each((model) => {
        const ppuWt = model.get('ppu_wt') || '0.00';
        const quantity = model.get('quantity');
        const totalWithoutDiscount = Currency.Math.mul(ppuWt, self.toCorrectQuantity(quantity));
        totalPrice = Currency.Math.add(
          totalPrice,
          totalWithoutDiscount,
        );
      });

      return totalPrice;
    },

    getTotalDiscountWt() {
      let totalPrice = '0.00';
      const self = this;

      this.each((model) => {
        const total = Currency.Math.mul(
          model.get('discount_ppu_wt') || '0.00',
          self.toCorrectQuantity(model.get('quantity')),
        );
        totalPrice = Currency.Math.add(
          totalPrice,
          total,
        );
      });

      return totalPrice;
    },

    toCorrectQuantity(quantity) {
      const intVal = parseInt(quantity);
      if (_.isNaN(intVal)) {
        console.error(`Quantity is not a number: ${quantity}`);
      }
      const floatVal = parseFloat(intVal);
      return floatVal.toFixed(2);
    },

    hasCardWithCode(code) {
      let exists = false;
      this.each((model) => {
        const type = model.get('type');
        const isCard = type === this.TYPE_MEMBERCARD
            || type === this.TYPE_MEMBERCARD_GIFTCARD
            || type === this.TYPE_GIFTCARD
            || type === this.TYPE_TOP_UP;
        if (code === model.get('code') && isCard) {
          exists = true;
        }
      });
      return exists;
    },

    getProductIds() {
      const productIds = [];

      this.each((model) => {
        if (model.has('product_id')) {
          productIds.push(model.get('product_id'));
        }
      });

      return productIds;
    },

  });
  return OrderItemCollection;
});
