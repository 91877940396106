define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/EmployeeCalendar',
  'modules/shop.cash-register-retail/models/employeeCalendar',

  'modules/shop.cash-register-retail/collections/employee',

  'modules/common/components/moment',
  'modules/common/components/locale',
  'modules/common/components/string',
], (
  $, _, Backbone, CalendarCollection, CalendarModel,
  EmployeeCollection,
  Moment, Locale, String,
) => {
  const Collection = CalendarCollection.extend({

    model: CalendarModel,

    dateLastFetch: null,

    load() {
      const def = new $.Deferred();

      if (this.allowNextFetch()) {
        EmployeeCollection.load()
          .then(() => {
            this.fetch(this._getParameters())
              .then(() => {
                this.ensureEmployeeCalendar()
                  .then(() => {
                    this.dateLastFetch = new Moment();
                    def.resolve();
                  }, def.resolve);
              }, def.reject);
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    allowNextFetch() {
      // Check if there was ever a fetch
      if (!this.dateLastFetch) return true;

      // Check if the last fetch was more then an 30 minutes
      const currentDate = new Moment();
      return currentDate.diff(this.dateLastFetch, 'minutes') > 30;
    },

    _getParameters() {
      const parameters = {
        start: 0,
        limit: 0,
        filters: [
          {
            name: 'relation_data_id__in_list',
            multi_val: EmployeeCollection.pluck('group_member.relation_data_id'),
          },
        ],
      };

      // Add last fetch parameter
      if (this.dateLastFetch) {
        parameters.filters.push({
          name: 'date_created__>',
          val: this.dateLastFetch.format(),
        });
      }

      return {
        params: parameters,
        add: true,
        merge: true,
        remove: false,
      };
    },

    ensureEmployeeCalendar() {
      const def = new $.Deferred();
      const callStack = [];

      EmployeeCollection.each((model) => {
        // Check if employee does not have a calendar
        if (!this.findWhere({ relation_data_id: model.get('group_member.relation_data_id') })) {
          // Create calendar item
          const calendarDef = new $.Deferred();
          const calendarModel = new CalendarModel({
            title: Locale.translate('{0}_s_calendar', model.get('managed_subuser.relation_data_profile.name')),
            published: false,
            relation_data_id: model.get('group_member.relation_data_id'),
            color: this.getRandomColor(),
          });

          // Add to call stack
          callStack.push(calendarDef);

          // Save model
          calendarModel.save()
            .then((id) => {
              calendarModel.set('id', id);
              // Fetch model
              calendarModel.fetch()
                .then(() => {
                  // Add model to this collection
                  this.add(calendarModel);
                  calendarDef.resolve();
                }, calendarDef.reject);
            }, calendarDef.reject);
        }
      });

      $.when.apply($, callStack)
        .then(def.resolve, def.reject);

      return def;
    },

    getRandomColor() {
      const colors = [
        '#c4a953',
        '#38bcc0',
        '#a57443',
        '#d03f4f',
        '#1a94b0',
        '#c48126',
        '#6fb1c1',
        '#e89100',
      ];

      return colors[Math.floor(Math.random() * colors.length)];
    },

    unload() {
      this.reset();
      this.dateLastFetch = null;
    },

  });

  return new Collection();
});
