define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ProductsModule/collections/TaxRate',
  'modules/common/components/locale',
], (
  $, _, Backbone, UpxCollection, Locale,
) => {
  const Collection = UpxCollection.extend({

    ALIAS_SPECIAL_COMMUNITY_INTRA: 'special_community_intra',
    ALIAS_SPECIAL_COMMUNITY_INTRA_SERVICE: 'special_community_intra_service',
    ALIAS_SPECIAL_NOT_APPLICABLE: 'special_outside_eu',
    ALIAS_SPECIAL_EMBALLAGE_PACKAGING: 'special_emballage_packaging',
    ALIAS_SPECIAL_MARGIN: 'special_margin',

    offline: false,

    loaded: false,

    load(force) {
      const def = new $.Deferred();

      if (!this.loaded || force) {
        this.fetch(this._getParams())
          .then(() => {
            this.loaded = true;
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) this.unload();

      this.load()
        .then(def.resolve, def.reject);

      return def;
    },

    _getParams() {
      return {
        params: {
          start: 0,
          limit: 0,
        },
        add: true,
        remove: false,
        merge: true,
      };
    },

    unload() {
      this.loaded = false;
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup(); // clean the cache to not load old profile
      }
    },

    getByAliasAndCountry(alias, countryIso2) {
      const taxRateModel = this.findWhere({ alias, country_iso2: countryIso2 });
      if (!taxRateModel) {
        throw new Error(`No tax rate found with alias "${alias}" and country_iso2 "${countryIso2}"`);
      }
      return taxRateModel;
    },

    getTaxRateDescription(alias, country) {
      if (country === 'EU') {
        if (alias === this.ALIAS_SPECIAL_COMMUNITY_INTRA) {
          return Locale.translate('intra_community_vat');
        }
        if (alias === this.ALIAS_SPECIAL_COMMUNITY_INTRA_SERVICE) {
          return Locale.translate('intracommunity_service_vat');
        }
        if (alias === this.ALIAS_SPECIAL_NOT_APPLICABLE) {
          return Locale.translate('world_vat_0percent_outside_eu');
        }
      }
      if (country === 'WO') {
        if (alias === 'special_applicable_not_vat') {
          return Locale.translate('not_applicable');
        }
        if (alias === 'special_margin') {
          return Locale.translate('margin');
        }
        if (alias === this.ALIAS_SPECIAL_EMBALLAGE_PACKAGING) {
          return Locale.translate('emballage');
        }
      }
      return '';
    },

    getTaxRateTitleForModel(tax) {
      const description = this.getTaxRateDescription(tax.get('alias'), tax.get('country_iso2'));
      if (description !== '') {
        const percent = this.formatPercentage(tax.get('value'));
        if (description.indexOf(percent) === -1) {
          return `${percent} ${description}`;
        }
        return description;
      }
      const taxRateTitleForValue = this.getTaxRateTitleForValue(tax.get('value'));

      return taxRateTitleForValue;
    },

    getTaxRateTitleForValue(value) {
      const percentage = parseFloat(value) * 100;
      if (_.isNumber(percentage)) {
        return Locale.translate('vat_{percentage}percent', { percentage });
      }
      console.error('Wrong tax value passed', { value });
      return '';
    },

    formatPercentage(value) {
      const val = parseFloat(value) * 100;
      return `${val}%`;
    },
  });

  return new Collection();
});
